import React, { Fragment, useState } from "react";
import { Box, Button, IconButton, Popover, Tooltip, Divider } from "@material-ui/core";
import { HeadsetMic } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { analytics } from "../../services/firebase";

export default function OpcionesHeader({ claimUser }) {
	const classes = useStyles();
	const [anchorElAyuda, setAnchorElAyuda] = useState(null);
	const openPopover = Boolean(anchorElAyuda);
	const idAyuda = openPopover ? "simple-popover" : undefined;

	const handleOpenAyuda = (event) => {
		setAnchorElAyuda(event.currentTarget);
	};

	const handleCloseAyuda = () => {
		setAnchorElAyuda(null);
	}

	const handleAnalytics = (nombre) => {
		analytics.logEvent(nombre, {
			usuarioCorreo: claimUser.email,
			usuarioNombre: claimUser.name,
			usuarioContrato: claimUser.proyecto,
		});
	}

	return (
		<Fragment>
			<Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"} style={{ width: "100%" }}>
				{/* BOTÓN AYUDA CYDOCS */}
				<Button
					color="primary"
					onClick={handleOpenAyuda}
					className={classes.ButtonLink}
				>
					Ayuda CyDocs
				</Button>

				<Divider
					orientation="vertical"
					flexItem
					className={classes.Divider}
				/>

				{/* BOTÓN ACTUALIZACIONES CYDOCS */}
				<Button
					href={LINKS.POLITICAS_SEGURIDAD}
					target="_blank"
					rel="noreferrer"
					color="primary"
					onClick={() => handleAnalytics(ANALYTICS.ACTUALIZACIONES)}
					className={classes.ButtonLink}
				>
					Políticas de Seguridad
				</Button>

				<Divider
					orientation="vertical"
					flexItem
					className={classes.Divider}
				/>

				{/* BOTÓN WEB CYD */}
				<Button
					href={LINKS.WEB_CYD}
					target="_blank"
					rel="noreferrer"
					onClick={() => handleAnalytics(ANALYTICS.WEB_CYD)}
					color="primary"
					className={classes.ButtonLink}
				>
					Web CyD
				</Button>

				<Divider
					orientation="vertical"
					flexItem
					className={classes.Divider}
				/>

				{/* BOTÓN MI CYD */}
				<Button
					href={LINKS.MI_CYD}
					target="_blank"
					rel="noreferrer"
					onClick={() => handleAnalytics(ANALYTICS.MI_CYD)}
					color="primary"
					className={classes.ButtonLink}
				>
					Mi CyD
				</Button>

				{/* BOTÓN MESA DE AYUDA */}
				<Tooltip title="Mesa de Ayuda CyD">
					<IconButton
						href={LINKS.MESA_AYUDA_CYD}
						target="_blank"
						rel="noreferrer"
						style={{
							marginLeft: 25,
						}}
					>
						<HeadsetMic style={{ color: "#731f1f" }} />
					</IconButton>
				</Tooltip>
			</Box>

			<Popover
				id={idAyuda}
				open={openPopover}
				anchorEl={anchorElAyuda}
				onClose={handleCloseAyuda}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Button
					component="a"
					href={LINKS.AYUDA_RECLUTAMIENTO}
					target="_blank"
					style={{
						color: "#731f1f",
						textDecoration: "none",
						textTransform: "none",
					}}
					onClick={() => {
						handleAnalytics(ANALYTICS.AYUDA_RECLUTAMIENTO);
						setAnchorElAyuda(null);
					}}
				>
					<strong>Ayuda Gestor de Reclutamiento</strong>
				</Button>
			</Popover>
		</Fragment>
	);
}

const useStyles = makeStyles((theme) => ({
	ButtonLink: {
		fontWeight: "bold",
		fontSize: "0.9rem",
		textTransform: "none"
	},
	Divider: {
		marginTop: 10,
		marginBottom: 10,
		marginLeft: 5,
		marginRight: 5,
	}
}));

const ANALYTICS = {
	AYUDA_RECLUTAMIENTO: "Home_link_superior_Ayuda_reclutadores",
	ACTUALIZACIONES: "Home_link_superior_Politicas_Seguridad_CyDocs",
	WEB_CYD: "Home_link_superior_WebCyD",
	MI_CYD: "Home_link_superior_plan_2022",
}

const LINKS = {
	AYUDA_RECLUTAMIENTO: "https://sites.google.com/cydingenieria.cl/gestordereclutamiento",
	ACTUALIZACIONES: "https://datastudio.google.com/reporting/a9bdabb2-b079-4675-8869-c92af0739212",
	WEB_CYD: "https://cydingenieria.cl/",
	MI_CYD: "https://www.micyd.cl",
	MESA_AYUDA_CYD: "https://soportecyd.sd.cloud.invgate.net/",
	POLITICAS_SEGURIDAD: "https://politicas.cydocs.cl"
}