import React, { Fragment } from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function Example(props) {
  const {
    popUp,
  } = props;

  const classes = useStyles();

  var items = [
    {
      imagen: "https://storage.googleapis.com/mi-cyd/CyDocs/Banner_app.png",
      ruta: "https://www.google.cl",
      popUp: "Si",
    }, {
      imagen: "https://storage.googleapis.com/mi-cyd/CyDocs/Banner_Portafolio.png",
      ruta: "https://portafolio.cydingenieria.com/",
      popUp: "No",
    }, {
      imagen: "https://storage.googleapis.com/mi-cyd/CyDocs/Banner_Adquisiciones.png",
      ruta: "https://sda.cydingenieria.com/",
      popUp: "No",
    },
  ];

  return (
    <Fragment>
      <Carousel
        autoPlay={false}
        indicators={true}
        navButtonsAlwaysVisible={true}
        animation="slide"
        fullHeightHover={true}
      >
        {items.map((item, index) =>
          <div key={`div_${index}`}>
            {item.popUp === "Si" && (
              <Box onClick={popUp} className={classes.box}>
                <img src={item.imagen} alt="fotografia" className={classes.image} />
              </Box>
            )}
            {item.popUp === "No" && (
              <Box className={classes.box}>
                <a href={item.ruta} target="_blank" rel="noreferrer">
                  <img src={item.imagen} alt="fotografia" className={classes.image} />
                </a>
              </Box>
            )}
          </div>
        )}
      </Carousel>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: "center",
  },
  image: {
    width: "100%",
  },
}));

export default Example;